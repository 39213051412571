<script>
import {
  ArrowUpIcon,
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedinIcon,
  BellIcon,
  BellOffIcon,
} from "vue-feather-icons";
// import { Slide } from "vue-carousel";
// import { Carousel } from "vue-carousel";
// import { VueTyper } from "vue-typer";
import Multiselect from "vue-multiselect";
import IndexService from "@/MainServices/IndexService.js";
import Navbar from "@/view/front/components/navbar";
import Switcher from "@/view/front/components/switcher";
import Footer from "@/view/front/components/footer";
import axios from "axios";
import { API_URL, MEDIA_URL } from "@/core/api";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT, REGISTER } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

/**
 * page-index-home component
 */
export default {
  data() {
    return {
      loading: true,
      mediaUrl: MEDIA_URL,
      apiUrl: API_URL,
      token:
        localStorage.getItem("token") !== null
          ? `Bearer ${localStorage.getItem("token")}`
          : null,
      data: [],
      search_text: "",
      selected_cities: [],
      city: "",
      cities: [],
      originalData: [],
      show_load_more: true,
      page_num: 1,
      sectorsData: [],
      jobIndustries: [],
      CompaniesList: [],
      featureData: [],
      spotlightData: [],
      urgentData: [],
      featureCompaniesData: [],
      descriptionSearch: false,
      form: {
        email: "",
        password: "",
      },
    };
  },
  components: {
    Navbar,
    Switcher,
    Footer,
    // Carousel,
    // Slide,
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    ArrowUpIcon,
    // "vue-typer": VueTyper,
    Multiselect,
    // Skeleton,
    BellIcon,
    BellOffIcon,
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["currentUser"]),
  },
  mounted() {
    IndexService.getCities().then((res) => {
      res.data.map((el) => {
        this.cities.push({
          id: el.id,
          value: el.city_name,
        });
      });
    });
    this.getSectors();
    this.getFeatureJobs();
    this.getSpotlightJobs();
    this.getFeatureCompanies();
    this.getUrgentJobs();
    this.getJobIndustries();
    this.getCompaniesList();
    if (window.location.href.indexOf("register") !== -1) {
      this.showForm("signup");
    }
    // if (window.location.href.indexOf("forgot") !== -1) {
    //   this.showForm("forgot");
    // }
    const signin_form = KTUtil.getById("kt_login_signin_form");
    const signup_form = KTUtil.getById("kt_login_signup_form");
    // const forgot_form = KTUtil.getById("kt_login_forgot_form");

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Username is required",
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: "Password is required",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv1 = formValidation(signup_form, {
      fields: {
        fullname: {
          validators: {
            notEmpty: {
              message: "Full name is required",
            },
          },
        },
        email: {
          validators: {
            notEmpty: {
              message: "Email is required",
            },
            emailAddress: {
              message: "The value is not a valid email address",
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: "Password is required",
            },
          },
        },
        cpassword: {
          validators: {
            notEmpty: {
              message: "Confirm password is required",
            },
            identical: {
              compare: function () {
                return signup_form.querySelector('[name="password"]').value;
              },
              message: "Passwords do not match",
            },
          },
        },
        agree: {
          validators: {
            notEmpty: {
              message: "Please accept terms and conditions",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    // this.fv2 = formValidation(forgot_form, {
    //   fields: {
    //     email: {
    //       validators: {
    //         notEmpty: {
    //           message: "Email is required",
    //         },
    //         emailAddress: {
    //           message: "The value is not a valid email address",
    //         },
    //       },
    //     },
    //   },
    //   plugins: {
    //     trigger: new Trigger(),
    //     submitButton: new SubmitButton(),
    //     bootstrap: new Bootstrap(),
    //   },
    // });
  },
  methods: {
    onSubmitLogin() {
      this.fv.validate();

      this.fv.on("core.form.valid", () => {
        var email = this.form.email;
        var password = this.form.password;

        // clear existing errors
        this.$store.dispatch(LOGOUT);

        // set spinner to submit button
        const submitButton = this.$refs["kt_login_signin_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        setTimeout(() => {
          // send login request
          this.$store
            .dispatch(LOGIN, { email, password })
            // go to which page after successfully login

            .then((res) => {
              localStorage.setItem("user", JSON.stringify(res));
              localStorage.setItem("token", res.token);
              // this.$router.push({ name: "dashboard" });
              // window.location.reload();
              window.location.href = "/dashboard";
            });

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        }, 1000);
      });

      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
      });
    },

    onSubmitRegister() {
      this.fv1.validate();
      this.fv1.validate().then((res) => {
        if (res == "Valid") {
          const firstName = this.$refs.firstName.value;
          // const lastName = this.$refs.lastName.value;
          const email = this.$refs.remail.value;
          const password = this.$refs.rpassword.value;
          // const compnay_name =
          //   this.recruiterSignUp == true || this.agencySignUp == true
          //     ? this.$refs.compnay_name.value
          //     : null;

          // clear existing errors
          this.$store.dispatch(LOGOUT);

          console.log("first-name", firstName);
          console.log("email", email);
          console.log("first-name", password);

          // set spinner to submit button
          const submitButton = this.$refs["kt_login_signup_submit"];
          submitButton.classList.add(
            "spinner",
            "spinner-light",
            "spinner-right"
          );

          // dummy delay
          setTimeout(() => {
            // send register request
            this.$store.dispatch(REGISTER, {
              email: email,
              password: password,
              name: firstName,
              compnay_name: null,
              role_id:
                this.recruiterSignUp == true
                  ? "2"
                  : this.agencySignUp == true
                  ? "6"
                  : "1",
            });
            // .then(() => {
            //   axios.post(`${this.apiUrl}/register`, {
            //     email: email,
            //     name: firstName,
            //     password: password,
            //     role_id: "3",
            //   });
            // })
            // .then((res) => {
            //   localStorage.setItem("user", JSON.stringify(res));
            //   localStorage.setItem("token", res.token);
            //   if (localStorage.getItem("route") !== null) {
            //     window.location.href = localStorage.getItem("route");
            //   } else {
            //     // window.location.href = "/dashboard";
            //   }
            // });

            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          }, 2000);
        } else {
          Swal.fire({
            title: "",
            text: "Please, provide correct data!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        }
      });

      this.fv1.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
      });
    },
    _descriptionSearch(event) {
      this.descriptionSearch = event.target.checked;
    },
    search() {
      this.$router.push({
        name: "search",
        params: {
          search_text: this.search_text,
          selected_cities: this.selected_cities,
          description_search: this.descriptionSearch,
        },
      });
    },
    getSectors() {
      axios.get(`${this.apiUrl}/get-job-sectors`).then((response) => {
        this.sectorsData = response.data;
        this.loading = false;
      });
    },
    getJobIndustries() {
      axios.get(`${this.apiUrl}/get-job-industries`).then((response) => {
        this.jobIndustries = response.data;
      });
    },
    getFeatureJobs() {
      axios.get(`${this.apiUrl}/get-feature-jobs`).then((response) => {
        this.featureData = response.data;
      });
    },
    getSpotlightJobs() {
      axios.get(`${this.apiUrl}/get-spotlight-jobs`).then((response) => {
        this.spotlightData = response.data;
      });
    },
    getUrgentJobs() {
      axios.get(`${this.apiUrl}/get-urgent-jobs`).then((response) => {
        this.urgentData = response.data;
      });
    },
    getCompaniesList() {
      this.loading = true;
      axios.get(`${this.apiUrl}/get-companies-list`).then((response) => {
        this.CompaniesList = response.data;
      });
    },
    getFeatureCompanies() {
      if (this.token !== null) {
        axios.defaults.headers.common["Authorization"] = this.token;
        axios
          .get(`${this.apiUrl}/get-feature-companies-auth`)
          .then((response) => {
            this.featureCompaniesData = response.data;
          });
      } else {
        axios.get(`${this.apiUrl}/get-feature-companies`).then((response) => {
          this.featureCompaniesData = response.data;
        });
      }
    },
  },
};
</script>

<template>
  <div>
    <Navbar />

    <!-- Hero Start -->
    <!-- <div class="container"> -->
    <section class="section_ch_p bg-white d-table w-100 margin_top_page">
      <!-- hero section -->
      <div class="row container" style="margin: 0 auto">
        <div class="col-lg-7 pl-0">
          <div class="py-20">
            <h1 class="hero_heading hero_heading_mb">
              Job portal with powerful features for targeted hassle-free
              <span class="hero_heading_child">hiring & job hunt</span>
            </h1>
          </div>
        </div>
        <div class="col-lg-5 pt-20">
          <img
            class="pl-3 hero_img"
            src="images/home/Homepagemainillustration.png"
            alt=""
          />
        </div>
      </div>
      <div class="form_home_div">
        <div class="">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <form action="job-listing.html">
              <div class="row row_fix pr-20">
                <div class="col-lg-7 home_top_form">
                  <div class="row __pl pr-8">
                    <div
                      class="
                        col-md-5
                        form-group
                        m-0
                        pl-0
                        custom_input
                        position__ch
                      "
                    >
                      <!-- <label class="labelch7">WHAT</label> -->
                      <input
                        type="text"
                        v-model="search_text"
                        @keyup.enter="search()"
                        class="form-control fieldch_new selectch"
                        placeholder="type your dream job"
                      />
                      <img
                        src="/images/s_1.svg"
                        width="15"
                        height="15"
                        alt="..."
                        class="icon_search__"
                      />
                    </div>

                    <div class="col-md-5 form-group m-0 p-0 position__ch">
                      <!-- <label class="labelch7">WHERE</label> -->
                      <multiselect
                        class="fieldch_new selectch home_city_selct"
                        id="multiselectCities"
                        v-model="selected_cities"
                        :height="300"
                        :options="cities"
                        :multiple="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :preserve-search="true"
                        placeholder="location you love"
                        label="value"
                        track-by="value"
                        :preselect-first="false"
                      >
                      </multiselect>
                      <img
                        src="images/s_2.svg"
                        width="15"
                        height="15"
                        alt="..."
                        class="icon_search__"
                      />
                    </div>
                    <div class="col-md-2 form-group home_btn_col_ch">
                      <!-- <div class="pull-right"> -->
                      <button
                        @click="search()"
                        type="button"
                        class="btn btn-default btn_red_redus"
                      >
                        Go Find
                      </button>
                      <!-- </div> -->
                    </div>

                    <div class="col-md-6 form-group mb-0 mt-2 pl-0">
                      <div class="checkboxch chabox_ch_home">
                        <label class="text-white pl-8"
                          ><input
                            type="checkbox"
                            value="Yes"
                            style="margin-left: -25px"
                            class="form-check-input"
                            v-on:click="_descriptionSearch($event)"
                          />
                          Search job title only</label
                        >
                      </div>
                    </div>
                    <div class="col-sm-12 form-group m-0 pl-0">
                      <p class="mt-2 mb-0 text-white">
                        <b class="text-white ch_link_c2">Quick searches:</b
                        >&nbsp;&nbsp;<a
                          class="ch_link_c text-white"
                          href="/job-sectors"
                          >Jobs By Sectors</a
                        >
                        |
                        <a class="ch_link_c text-white" href="/jobs-in-pakistan"
                          >Graduate jobs</a
                        >
                        |
                        <a class="ch_link_c text-white" href="#">Internships</a>
                        |
                        <a class="ch_link_c text-white" href="/jobs-in-pakistan"
                          >Urgent Jobs</a
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-5"></div>
            </form>
          </div>
        </div>
      </div>
      <!-- hero section -->

      <!-- newest post section -->
      <div style="background: #e1e8f4">
        <div class="container row" style="margin: 0 auto">
          <div class="col-lg-12 hight_section_dif">
            <!-- <span class="hr_width">
              <hr />
            </span> -->
          </div>
          <div class="col-lg-8 mb-17">
            <div class="row newest_hearder">
              <div class="col-lg-7 col-12 text-newst">Latest jobs</div>
              <div class="col-lg-5 col-12 dollg5">
                <ul
                  class="nav nav-pills newest_tabs d-flex"
                  id="pills-tab"
                  role="tablist"
                  style="background: transparent"
                >
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link active newest_btn"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      By sector
                    </button>
                  </li>
                  <li class="nav-item text-center" role="presentation">
                    <button
                      class="nav-link newest_btn"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      By industry
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 tabpanel_style p-5 text-white">
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div class="row">
                      <div
                        class="col-6 col-lg-4"
                        v-for="(item, index) in jobIndustries"
                        :key="index"
                        :style="index < 21 ? '' : 'display: none '"
                      >
                        <div class="newst_secter_list">
                          <router-link
                            v-if="index < 20"
                            :to="
                              '/jobs-careers-pakistan/jobs-industry/' +
                              item.industry_name
                                .toLowerCase()
                                .replace(/ /g, '-')
                                .replace(/[^\w-]+/g, '')
                            "
                            >{{ item.industry_name }}
                            <!-- <i class="pull-right fa fa-angle-right"></i>-->
                          </router-link>
                          <router-link
                            v-if="index === 20"
                            :to="'/job-industrys'"
                            >View All
                            <i class="ml-1 text-white fa fa-angle-right"></i>
                            <!-- <i class="pull-right fa fa-angle-right"></i>-->
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade show active"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <div class="row">
                      <div
                        class="col-6 col-lg-4"
                        v-for="(item, index) in sectorsData"
                        :key="index"
                        :style="index < 21 ? '' : 'display: none '"
                      >
                        <div class="newst_secter_list">
                          <router-link
                            v-if="index < 20"
                            :to="
                              '/jobs-careers-pakistan/jobs-sector/' +
                              item.job_function_name
                                .toLowerCase()
                                .replace(/ /g, '-')
                                .replace(/[^\w-]+/g, '')
                            "
                            >{{ item.job_function_name }}
                            <!-- <i class="pull-right fa fa-angle-right"></i>-->
                          </router-link>
                          <router-link v-if="index === 20" :to="'/job-sectors'"
                            >View All
                            <i class="ml-1 text-white fa fa-angle-right"></i>
                            <!-- <i class="pull-right fa fa-angle-right"></i>-->
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 mb-17 hide_on_mobile_ch section_five_by_ch">
            <div class="bg-white">
              <div class="row recruitment_btn_box">
                <div class="col">
                  <span>
                    Hire <br />
                    industry’s top <br /><b>leaders!</b>
                  </span>
                  <button>
                    <a href="/recruiter-registeration"> Start recruitment </a>
                  </button>
                </div>
                <div
                  class="col"
                  style="
                    background: url(images/home/Hireillustration.png) no-repeat
                      center 0px;
                    background-size: contain;
                  "
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="py-17"
        style="
          background: url(images/home/BlueGradient.png) no-repeat center 0px;
          background-size: cover;
        "
      >
        <div class="container">
          <div class="row Book_a_session text-white">
            <div class="col-lg-9 col-12">
              <div class="firest">Command the brilliance in job interviews</div>
              <div class="scend">
                Stop worrying! Be prepared & confident. Get ready and breeze
                through it!
              </div>
            </div>
            <div class="col-lg-3 col-12 pt-8 bool_section_ch">
              <!-- <div class="button_book">Book a session</div> -->
              <router-link class="button_book" :to="'/contact-us/'"
                >Book a session
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <!-- newest post section end -->
      <!-- fucher premmiem jobs -->
      <div class="pt-17" style="background: #e1e8f4">
        <div class="container row" style="margin: 0 auto">
          <div class="col-lg-6 pl-0">
            <div class="bg-white py-4 px-4 featured_box_hight">
              <div class="col-lg-12 p-0">
                <div class="px-4">
                  <div class="feature_job_header row my-2">
                    <div class="col-lg-9 col-12 pb-2 pl-0 pr-0">
                      Featured jobs
                    </div>
                    <div
                      class="col-lg-3 col-12 p-0 chchchch"
                      style="text-align: end"
                    >
                      <router-link :to="'/jobs-in-pakistan'"
                        >View All</router-link
                      >
                      <i class="pl-2 pt-1 pull-right fa fa-angle-right"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 mt-5">
                <div class="row">
                  <div
                    class="col-xs-12 col-sm-6 col-md-4 col-lg-6 p-0"
                    v-for="(item, index) in featureData"
                    :key="index"
                  >
                    <div v-if="index < 8" class="well mr-1">
                      <div class="media">
                        <div class="media-left p-0 pr-2">
                          <router-link
                            v-if="index < 5"
                            :to="
                              '/jobs-careers/pakistan/' +
                              item.location_city
                                .map((v) => v.toLowerCase())
                                .join('-') +
                              '/' +
                              item.job_title
                                .toLowerCase()
                                .replace(/ /g, '-')
                                .replace(/[^\w-]+/g, '')+'/' +item.id
                            "
                          >
                            <img
                              class="media-object img-circle"
                              :src="mediaUrl + item.recruiter.profile_pic"
                              width="40"
                              height="40"
                              alt="logo"
                              style="object-fit: cover"
                            />
                          </router-link>
                        </div>
                        <div class="media-body media-right p-0">
                          <h5 class="media-heading text-primary">
                            <router-link
                              :to="
                                '/jobs-careers/pakistan/' +
                                item.location_city
                                  .map((v) => v.toLowerCase())
                                  .join('-') +
                                '/' +
                                item.job_title
                                  .toLowerCase()
                                  .replace(/ /g, '-')
                                  .replace(/[^\w-]+/g, '')+'/' +item.id
                              "
                            >
                              {{ item.job_title }}
                            </router-link>
                          </h5>
                          <p>{{ item.recruiter.company_name }}</p>
                          <p class="text-muted">
                            {{ item.location_city.join(", ") }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 pr-0">
            <div class="bg-white p-4 featured_box_hight">
              <div class="col-lg-12">
                <div class="feature_job_header row py-2 mb-2">
                  <div class="col-lg-9 col-12 p-0">Featured recruiters</div>
                  <div class="col-lg-3 col-12 chchchch" style="text-align: end">
                    <router-link :to="'/recruiter-directory'"
                      >View All</router-link
                    >
                    <i class="pl-2 pt-1 pull-right fa fa-angle-right"></i>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 mt-5">
                <div class="row">
                  <div
                    class="col-xs-12 col-sm-6 col-md-4 col-lg-6 p-0"
                    v-for="(item, index) in featureCompaniesData"
                    :key="index"
                  >
                    <div class="well mr-1" v-if="index < 6">
                      <div class="media">
                        <div class="media-left p-0 pr-2">
                          <router-link
                            :to="
                              '/employer/' +
                              item.company_name
                                .toLowerCase()
                                .replace(/ /g, '-')
                                .replace(/[^\w-]+/g, '')
                            "
                          >
                            <img
                              class="media-object img-circle"
                              :src="mediaUrl + item.profile_pic"
                              width="40"
                              style="object-fit: cover"
                              height="40"
                              alt="..."
                            />
                          </router-link>
                        </div>
                        <div class="media-body media-right p-0">
                          <h5 class="media-heading text-primary">
                            <router-link
                              :to="
                                '/employer/' +
                                item.company_name
                                  .toLowerCase()
                                  .replace(/ /g, '-')
                                  .replace(/[^\w-]+/g, '')
                              "
                            >
                              <router-link
                                :to="
                                  '/employer/' +
                                  item.company_name
                                    .toLowerCase()
                                    .replace(/ /g, '-')
                                    .replace(/[^\w-]+/g, '')
                                "
                                class="text-dark company-name"
                                >{{ item && item.company_name }}</router-link
                              >
                            </router-link>
                            <br />
                            <small class="designation text-muted">{{
                              item.branches[0].branchAddress
                            }}</small>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span class="hr_width hide_on_mobile_ch">
            <hr />
          </span>
        </div>
      </div>
      <!-- fucher premmiem jobs  end-->
      <!-- join our emplymnt -->
      <div class="hide_on_mobile_ch" style="background: #e1e8f4">
        <div class="">
          <div class="container" style="margin: 0 auto">
            <div class="row m-0">
              <div class="col-lg-4 pl-0 pr-8">
                <div class="row m-0 bg-white">
                  <div class="row newest_hearder_new__ m-0">
                    <div class="col row dollg5 p-0">
                      <div class="col-lg-9">
                        <ul
                          class="nav nav-pills newest_tabs"
                          id="pills-tab"
                          role="tablist"
                          style="background: transparent; float: none"
                        >
                          <li class="nav-item text-center">
                            <button
                              class="nav-link active newest_btn p-0"
                              id="pills-home-tab"
                              type="button"
                              style="
                                background: transparent !important;
                                font-weight: 600;
                                color: #106409 !important;
                                font-size: 20px;
                              "
                            >
                              Jobs by company
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div class="col-lg-3 pt-3 pr-0">
                        <button
                          class="pull-right pr-0"
                          style="color: #106409 !important"
                        >
                          <a href="/recruiter-directory"> View All </a>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row m-0">
                    <div class="col-lg-12 joinUs_left pt-5 pb-5 text-white">
                      <div class="tab-content" id="pills-tabContent">
                        <div
                          class="tab-pane fade show active"
                          id="pills-home"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                        >
                          <div class="row">
                            <div
                              class="col-xs-12 col-sm-6 col-md-4 col-lg-6 pl-0"
                              v-for="(item, index) in CompaniesList"
                              :key="index"
                            >
                              <div class="newst_secter_list">
                                <router-link
                                  :to="
                                    '/employer/' +
                                    item.company_name
                                      .toLowerCase()
                                      .replace(/ /g, '-')
                                      .replace(/[^\w-]+/g, '')
                                  "
                                  class="text-muted"
                                  >{{ item.company_name }}
                                  <!-- <i class="pull-right fa fa-angle-right"></i>-->
                                </router-link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="tab-pane fade"
                          id="pills-profile"
                          role="tabpanel"
                          aria-labelledby="pills-profile-tab"
                        >
                          <div class="row">
                            <div
                              class="col-xs-12 col-sm-6 col-md-4 col-lg-4"
                              v-for="(item, index) in sectorsData"
                              :key="index"
                            >
                              <div class="newst_secter_list">
                                <router-link
                                  :to="
                                    '/jobs-careers-pakistan/jobs-sector/' +
                                    item.job_function_name
                                      .toLowerCase()
                                      .replace(/ /g, '-')
                                      .replace(/[^\w-]+/g, '')
                                  "
                                  >{{ item.job_function_name }}
                                  <!-- <i class="pull-right fa fa-angle-right"></i>-->
                                </router-link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-8 pr-0 pl-10"
                style="
                  background: url(images/home/BlueGradient.png) no-repeat center
                    0px;
                  background-size: cover;
                "
              >
                <div class="mx-3">
                  <div class="row text-white py-15 border_join_keft">
                    <div class="container join chchch pl-0">
                      <div class="col pl-0">
                        <h1 class="fontch1">
                          Join our<br />employement<br />network
                        </h1>
                      </div>

                      <div class="col colch">
                        <div class="media_new">
                          <div class="media-left">
                            <a href="#"
                              ><img
                                class="media-object"
                                src="images/iconch.png"
                                width="35"
                                height="35"
                                alt="..."
                            /></a>
                          </div>
                          <div class="">
                            <h4 class="fontch2">
                              Apply to Jobs faster even from mobile
                            </h4>
                          </div>
                        </div>
                      </div>

                      <div class="col colch">
                        <div class="media_new">
                          <div class="media-left">
                            <a href="#"
                              ><img
                                class="media-object"
                                src="images/iconch.png"
                                width="35"
                                height="35"
                                alt="..."
                            /></a>
                          </div>
                          <div class="">
                            <h4 class="fontch2">
                              Post a resume to be found by registered empoyers
                            </h4>
                          </div>
                        </div>
                      </div>

                      <div class="col colch">
                        <div class="media_new">
                          <div class="media-left">
                            <a href="#"
                              ><img
                                class="media-object"
                                src="images/iconch.png"
                                width="35"
                                height="35"
                                alt="..."
                            /></a>
                          </div>
                          <div class="">
                            <h4 class="fontch2">Get response alert for free</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- join our emplymnt end -->
      <!-- borwoes by citys -->
      <!-- fucher premmiem jobs -->
      <div class="" style="background: #e1e8f4">
        <div class="container row" style="margin: 0 auto">
          <span class="hr_width">
            <hr />
          </span>
          <div class="col-lg-8 city_section_by_ch">
            <div class="bg-white p-4 featured_box_hight_new">
              <div class="col-lg-12 px-8">
                <div class="feature_job_header row pb-2">
                  <div class="col-lg-9 p-0">Browse by cities</div>
                  <!-- <div class="col-lg-3" style="text-align: end">
                    View All
                    <i class="pl-2 pt-1 pull-right fa fa-angle-right"></i>
                  </div> -->
                </div>
              </div>
              <div class="col-sm-12 mt-5">
                <div class="row">
                  <div class="col-lg-3 col-6 mb-8">
                    <router-link to="/jobs/Pakistan/Lahore" class="">
                      <img
                        class="media-object city_img_style"
                        src="images/home/city/Lahore.jpeg"
                        width=""
                        height=""
                        alt="..."
                      />
                    </router-link>
                  </div>
                  <div class="col-lg-3 col-6 mb-8">
                    <router-link to="/jobs/Pakistan/Faislabad" class="">
                      <img
                        class="media-object city_img_style"
                        src="images/home/city/Faislabad.jpeg"
                        width=""
                        height=""
                        alt="..."
                      />
                    </router-link>
                  </div>
                  <div class="col-lg-3 col-6 mb-8">
                    <router-link to="/jobs/Pakistan/Gujranwala" class="">
                      <img
                        class="media-object city_img_style"
                        src="images/home/city/Gujranwala.jpeg"
                        width=""
                        height=""
                        alt="..."
                      />
                    </router-link>
                  </div>
                  <div class="col-lg-3 col-6 mb-8">
                    <router-link to="/jobs/Pakistan/Islamabad" class="">
                      <img
                        class="media-object city_img_style"
                        src="images/home/city/Islamabad.jpeg"
                        width=""
                        height=""
                        alt="..."
                      />
                    </router-link>
                  </div>
                  <div class="col-lg-3 col-6 mb-8">
                    <router-link to="/jobs/Pakistan/Peshawar" class="">
                      <img
                        class="media-object city_img_style"
                        src="images/home/city/Peshawar.jpeg"
                        width=""
                        height=""
                        alt="..."
                      />
                    </router-link>
                  </div>
                  <div class="col-lg-3 col-6 mb-8">
                    <router-link to="/jobs/Pakistan/Quetta" class="">
                      <img
                        class="media-object city_img_style"
                        src="images/home/city/Quetta.jpeg"
                        width=""
                        height=""
                        alt="..."
                      />
                    </router-link>
                  </div>
                  <div class="col-lg-3 col-6 mb-8">
                    <router-link to="/jobs/Pakistan/Sialkot" class="">
                      <img
                        class="media-object city_img_style"
                        src="images/home/city/Sialkot.jpeg"
                        width=""
                        height=""
                        alt="..."
                      />
                    </router-link>
                  </div>
                  <div class="col-lg-3 col-6 mb-8">
                    <router-link to="/jobs/Pakistan/Karachi" class="">
                      <img
                        class="media-object city_img_style"
                        src="images/home/city/Karachi.jpeg"
                        width=""
                        height=""
                        alt="..."
                      />
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 city_section_by_ch2">
            <div class="bg-white p-4 featured_box_hight_new">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-12 form_heading_home">
                    Get started for your dream job
                  </div>
                  <div class="col-lg-12 form_heading_child p-0">
                    Create a quick account or login to get started
                  </div>
                </div>
              </div>
              <div class="col-sm-12 mt-6">
                <div class="row login_tab_hearder">
                  <div class="col-lg-12 dollg5 px-4">
                    <ul
                      class="nav nav-pills login_tabs"
                      id="pills-tab"
                      role="tablist"
                      style="background: transparent"
                    >
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link active newest_btn"
                          id="pill-sign-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pill-sign"
                          type="button"
                          role="tab"
                          aria-controls="pill-sign"
                          aria-selected="true"
                        >
                          SIGN UP
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link newest_btn"
                          id="pills-login-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-login"
                          type="button"
                          role="tab"
                          aria-controls="pills-login"
                          aria-selected="false"
                        >
                          LOGIN
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 pl-4 pb-0 pt-0 pr-5">
                    <div class="tab-content" id="pills-tabContent">
                      <div
                        class="tab-pane fade show active"
                        id="pill-sign"
                        role="tabpanel"
                        aria-labelledby="pill-sign-tab"
                      >
                        <div class="row">
                          <!-- ////////////////////////////////////////////////// -->
                          <div class="login-form login-signup">
                            <form
                              class="form"
                              novalidate="novalidate"
                              id="kt_login_signup_form"
                              @submit.stop.prevent="onSubmitRegister()"
                            >
                              <div class="form-group mb-3">
                                <input
                                  class="form-control fieldch"
                                  type="text"
                                  placeholder="Full Name"
                                  name="firstName"
                                  ref="firstName"
                                  autocomplete="off"
                                />
                              </div>
                              <div class="form-group mb-3">
                                <input
                                  class="form-control fieldch"
                                  type="email"
                                  placeholder="Email Address"
                                  name="email"
                                  ref="remail"
                                  autocomplete="off"
                                />
                              </div>
                              <div class="form-group mb-3">
                                <input
                                  class="form-control fieldch"
                                  type="password"
                                  placeholder="Enter Password"
                                  name="password"
                                  ref="rpassword"
                                  autocomplete="off"
                                />
                              </div>
                              <div class="form-group mb-3">
                                <input
                                  class="form-control fieldch"
                                  type="password"
                                  placeholder="Retype Password"
                                  name="cpassword"
                                  ref="cpassword"
                                  autocomplete="off"
                                />
                              </div>
                              <div
                                class="
                                  form-group
                                  d-flex
                                  flex-wrap
                                  pb-lg-0 pb-1
                                  mt-7
                                  mb-0
                                "
                              >
                                <button
                                  ref="kt_login_signup_submit"
                                  class="btn btn-default btn-lg home_auth_btn"
                                >
                                  Sign Up
                                </button>
                              </div>
                            </form>
                          </div>
                          <!-- /////////////////////////////////////////////////// -->
                        </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="pills-login"
                        role="tabpanel"
                        aria-labelledby="pills-login-tab"
                      >
                        <div class="row">
                          <!-- ///////////////////////////////login -->
                          <div class="login-form login-signin">
                            <form
                              class="form"
                              novalidate="novalidate"
                              id="kt_login_signin_form"
                              @submit.stop.prevent="onSubmitLogin()"
                            >
                              <div
                                class="pb-13 pt-lg-0 pt-5"
                                style="display: flex; flex-direction: column"
                              ></div>
                              <div class="form-group">
                                <div
                                  id="example-input-group-1"
                                  label=""
                                  label-for="example-input-1"
                                >
                                  <input
                                    class="form-control fieldch"
                                    type="text"
                                    name="email"
                                    placeholder="Enter Email Adderss"
                                    ref="email"
                                    v-model="form.email"
                                  />
                                </div>
                              </div>
                              <div class="form-group">
                                <div
                                  class="d-flex justify-content-between mt-n5"
                                >
                                  <label
                                    class="
                                      font-size-h6 font-weight-bolder
                                      text-dark
                                      pt-5
                                    "
                                  ></label>
                                  <a
                                    class="
                                      font-size-h6 font-weight-bolder
                                      text-hover-primary
                                      pt-5
                                      f-left
                                    "
                                    id="kt_login_forgot"
                                    href="/forgot"
                                    >Forgot Password ?</a
                                  >
                                </div>
                                <div
                                  id="example-input-group-2"
                                  label=""
                                  label-for="example-input-2"
                                >
                                  <input
                                    class="form-control fieldch"
                                    type="password"
                                    name="password"
                                    placeholder="Enter Password"
                                    ref="password"
                                    v-model="form.password"
                                    autocomplete="off"
                                  />
                                </div>
                              </div>
                              <div class="pb-lg-0 pb-5">
                                <button
                                  ref="kt_login_signin_submit"
                                  class="btn btn-default btn-lg home_auth_btn"
                                >
                                  Login
                                </button>
                              </div>
                            </form>
                          </div>
                          <!-- //////////////////////////////login -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span class="hight_section_dif">
            <!-- <hr /> -->
          </span>
        </div>
      </div>
      <!-- brows by city end -->
      <!-- hiring in pakistan -->
      <div class="p_by_ch_b" style="background: #e1e8f4">
        <div class="bg-white pb-5">
          <div class="container bg-white my-7">
            <div class="row">
              <div class="col-lg-12 pb-7">
                <div class="greeen_heading">Currently hiring in Pakistan</div>
              </div>
              <div class="col row pr-0">
                <div class="col-lg-2 col-4 p-0">
                  <img
                    class="img_w_h"
                    src="images/home/logos_hiring/01.svg"
                    alt="..."
                  />
                </div>
                <div class="col-lg-2 col-4 p-0">
                  <img
                    class="img_w_h"
                    src="images/home/logos_hiring/02.svg"
                    alt="..."
                  />
                </div>
                <div class="col-lg-2 col-4 p-0">
                  <img
                    class="img_w_h"
                    src="images/home/logos_hiring/03.svg"
                    alt="..."
                  />
                </div>
                <div class="col-lg-2 col-4 p-0">
                  <img
                    class="img_w_h"
                    src="images/home/logos_hiring/04.svg"
                    alt="..."
                  />
                </div>
                <div class="col-lg-2 col-4 p-0">
                  <img
                    class="img_w_h"
                    src="images/home/logos_hiring/05.svg"
                    alt="..."
                  />
                </div>
                <div class="col-lg-2 col-4 p-0">
                  <img
                    class="img_w_h"
                    src="images/home/logos_hiring/06.svg"
                    alt="..."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- hiring in pakistan -->

      <!-- fucher premmiem jobs  end-->
      <!-- <div class="row">
        <div class="container sector">
          <h1
            class="heading_h_ch align-center m_title"
            style="background: url(images/hr-bg.png) no-repeat center 40px"
          >
            Search by Sectors
          </h1>
        </div>
      </div> -->
      <!--img box section-->
      <!-- <div class="container mt-10 mb-30" style="margin-top: -1.5rem !important">
        <div class="row justify-content-center">
          <div class="col-sm-12 col-md-12 col-lg-12 row">
            <div class="col-sm-3 pl-0">
              <div class="thumbnailch" style="min-height: 93%">
                <img
                  src="images/close-up-image-programer-working-his-desk-office.png"
                  alt="Lights"
                  style="width: 100%; height: 223px"
                />
                <div class="captionch align-center">
                  <router-link
                    :to="'/jobs-careers-pakistan/jobs-sector/software--web-development'"
                    ><h1 class="img_saction_heading">
                      IT, Software & Web
                    </h1></router-link
                  >
                </div>
              </div>
            </div>

            <div class="col-sm-3">
              <div class="thumbnailch" style="min-height: 93%">
                <img
                  src="images/medium-shot-man-with-mask-project.png"
                  alt="Lights"
                  style="width: 100%; height: 223px"
                />
                <div class="captionch align-center">
                  <h1 class="img_saction_heading">Engineering</h1>
                </div>
              </div>
            </div>

            <div class="col-sm-3">
              <div class="thumbnailch" style="min-height: 93%">
                <img
                  src="images/close-up-pen-financial-report-with-window-background.png"
                  alt="Lights"
                  style="width: 100%; height: 223px"
                />
                <div class="captionch align-center">
                  <h1 class="img_saction_heading">Banking & Finance</h1>
                </div>
              </div>
            </div>

            <div class="col-sm-3 pr-0">
              <div class="thumbnailch" style="min-height: 93%">
                <img
                  src="images/digital-increasing-bar-graph-with-businessman-hand-overlay.png"
                  alt="Lights"
                  style="width: 100%; height: 223px"
                />
                <div class="captionch align-center">
                  <router-link :to="'/jobs-sector/marketing'"
                    ><h1 class="img_saction_heading">
                      Marketing & BD
                    </h1></router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="row">
              <div
                class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                v-for="(item, index) in sectorsData"
                :key="index"
              >
                <div class="well">
                  <router-link
                    :to="
                      '/jobs-careers-pakistan/jobs-sector/' +
                      item.job_function_name
                        .toLowerCase()
                        .replace(/ /g, '-')
                        .replace(/[^\w-]+/g, '')
                    "
                    >{{ item.job_function_name }}
                    <i class="pull-right fa fa-angle-right"></i
                  ></router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!--Spotlight Jobs Across Pakistan-->
      <!-- <div
        class="row spotlight"
        style="background: url(images/spotlight_bg.jpg) no-repeat center center"
      >
        <div class="container">
          <h1
            class="heading-inverse align-center m_title"
            style="
              text-transform: none !important;
              background: url(images/hr-inverse.png) no-repeat center 48px;
            "
          >
            Spotlight jobs in pakistan
          </h1>
          <div class="col-sm-12">
            <div
              class="row"
              v-for="(item, index) in spotlightData"
              :key="index"
            >
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                <div class="well">
                  <div class="media">
                    <div class="media-left">
                      <router-link
                        :to="
                          '/jobs-careers/pakistan/' +
                          item.location_city
                            .map((v) => v.toLowerCase())
                            .join('-') +
                          '/' +
                          item.job_title
                            .toLowerCase()
                            .replace(/ /g, '-')
                            .replace(/[^\w-]+/g, '')
                        "
                      >
                        <img
                          class="media-object img-circle"
                          :src="mediaUrl + item.recruiter.profile_pic"
                          width="80"
                          height="80"
                          alt="..."
                        />
                      </router-link>
                    </div>
                    <div class="media-body media-right">
                      <h4 class="media-heading">{{ item.job_title }}</h4>
                      <p class="text-primary">
                        {{ item.recruiter.company_name }}
                      </p>
                      <p class="text-muted">
                        {{ item.location_city.join(", ") }}
                      </p>
                      <router-link
                        :to="
                          '/jobs-careers/pakistan/' +
                          item.location_city
                            .map((v) => v.toLowerCase())
                            .join('-') +
                          '/' +
                          item.job_title
                            .toLowerCase()
                            .replace(/ /g, '-')
                            .replace(/[^\w-]+/g, '')
                        "
                        class="btn btn-default btn-xs"
                        style="color: white !important"
                        >Apply Now
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!--Featured Jobs-->
      <!-- <div class="row">
        <div class="container featured-jobs">
          <h1 class="heading_featured align-center m_title">Featured jobs</h1>
          <br />

          <div class="col-sm-12">
            <div class="row">
              <div
                class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                v-for="(item, index) in featureData"
                :key="index"
              >
                <div class="well">
                  <div class="media">
                    <div class="media-left">
                      <router-link
                        :to="
                          '/jobs-careers/pakistan/' +
                          item.location_city
                            .map((v) => v.toLowerCase())
                            .join('-') +
                          '/' +
                          item.job_title
                            .toLowerCase()
                            .replace(/ /g, '-')
                            .replace(/[^\w-]+/g, '')
                        "
                      >
                        <img
                          class="media-object img-circle"
                          :src="mediaUrl + item.recruiter.profile_pic"
                          width="48"
                          height="48"
                          alt="..."
                        />
                      </router-link>
                    </div>
                    <div class="media-body media-right">
                      <h5 class="media-heading text-primary">
                        <router-link
                          :to="
                            '/jobs-careers/pakistan/' +
                            item.location_city
                              .map((v) => v.toLowerCase())
                              .join('-') +
                            '/' +
                            item.job_title
                              .toLowerCase()
                              .replace(/ /g, '-')
                              .replace(/[^\w-]+/g, '')
                          "
                        >
                          {{ item.job_title }}
                        </router-link>
                      </h5>
                      <p>{{ item.recruiter.company_name }}</p>
                      <p class="text-muted">
                        {{ item.location_city.join(", ") }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12"><br /><br /></div>
          <div class="row">
            <div class="col-sm-12">
              <div class="row">
                <div class="col-xs-12 col-sm-6">
                  <div class="pull-right">
                    <a
                      href="job-listing.html"
                      class="
                        btn btn-default btn-lg
                        outline-btnch
                        Upload_Your_CV
                      "
                      >Load more</a
                    >
                  </div>
                </div>

                <div class="col-xs-12 col-sm-6">
                  <div class="pull-left">
                    <a
                      href="applicants/manage-cv.html"
                      class="btn btn-default btn-lg top-form-btn Upload_Your_CV"
                      >Upload your CV</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!--Urgent Jobs-->
      <!-- <div class="row">
        <div class="container featured-jobs">
          <h1 class="heading_featured align-center m_title">Urgent jobs</h1>
          <br />
          <div class="col-sm-12">
            <div class="row">
              <div
                class="col-xs-12 col-sm-6 col-md-4 col-lg-2"
                v-for="(item, index) in urgentData"
                :key="index"
              >
                <div class="well_new">
                  <div class="media">
                    <div class="media-body media-right">
                      <h5 class="media-heading text-primary">
                        <router-link
                          :to="
                            '/jobs-careers/pakistan/' +
                            item.location_city
                              .map((v) => v.toLowerCase())
                              .join('-') +
                            '/' +
                            item.job_title
                              .toLowerCase()
                              .replace(/ /g, '-')
                              .replace(/[^\w-]+/g, '')
                          "
                        >
                          {{ item.job_title }}
                        </router-link>
                      </h5>
                      <p>{{ item.recruiter.company_name }}</p>
                      <p class="text-muted">
                        {{ item.location_city.join(", ") }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12"><br /><br /></div>
        </div>
      </div> -->
      <!-- start ch -->
      <!-- <div
        class="row join-us"
        style="
          background: url(images/section_bgch.jpg) no-repeat center center;
          margin-bottom: 4em;
        "
      >
        <div class="container join chchch">
          <div class="col-sm-3">
            <h1 class="fontch1">Join our<br />employement<br />network</h1>
          </div>

          <div class="col-sm-3 colch">
            <div class="media">
              <div class="media-left">
                <a href="#"
                  ><img
                    class="media-object"
                    src="images/iconch.png"
                    width="48"
                    height="48"
                    alt="..."
                /></a>
              </div>
              <div class="media-body media-right">
                <h4 class="fontch2">Apply to Jobs faster even from mobile</h4>
              </div>
            </div>
          </div>

          <div class="col-sm-3 colch">
            <div class="media">
              <div class="media-left">
                <a href="#"
                  ><img
                    class="media-object"
                    src="images/iconch.png"
                    width="48"
                    height="48"
                    alt="..."
                /></a>
              </div>
              <div class="media-body media-right">
                <h4 class="fontch2">
                  Post a resume to be found by registered empoyers
                </h4>
              </div>
            </div>
          </div>

          <div class="col-sm-3 colch">
            <div class="media">
              <div class="media-left">
                <a href="#"
                  ><img
                    class="media-object"
                    src="images/iconch.png"
                    width="48"
                    height="48"
                    alt="..."
                /></a>
              </div>
              <div class="media-body media-right">
                <h4 class="fontch2 hpch">Get response alert for free</h4>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- end ch -->
      <!-- <div class="container" style="margin-top: 4%">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h1 class="heading_featured align-center m_title">
                Browse local jobs
              </h1>
            </div>
          </div>
        </div> 

        <div class="row">
          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="title h6 text-dark thumbnailch">
              <div class="card catagories overflow-hidden rounded border-0">
                <div style="display: flex; justify-content: center">
                  <router-link
                    to="/jobs/Pakistan/Lahore"
                    class="job_city_title"
                    style="
                      position: absolute;
                      font-size: 24;
                      font-size: 28px;
                      margin-top: 24%;
                      color: white;
                    "
                    >LAHORE</router-link
                  >
                </div>
                <img
                  src="http://historic.job4u.pk/htmlcss/images/punjab.jpg"
                  class="img-fluid"
                  alt=""
                />

                <div class="card-body">
                  <ul
                    class="list-unstyled d-flex justify-content-between mb-0"
                    style="flex-direction: column"
                  >
                    <li
                      class="h6 mb-0 jobs"
                      style="margin-bottom: 3% !important"
                    >
                      <b>Jobs in punjab</b>
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Lahore"
                        class="title h6 text-dark"
                        >Jobs in lahore</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Sialkot"
                        class="title h6 text-dark"
                        >Jobs in sialkot</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Gujranwala"
                        class="title h6 text-dark"
                        >Jobs in gujranwala</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Faisalabad"
                        class="title h6 text-dark"
                        >Jobs in faisalabad</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Rawalpindi"
                        class="title h6 text-dark"
                        >Jobs in rawalpindi</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="title h6 text-dark thumbnailch">
              <div class="card catagories overflow-hidden rounded border-0">
                <div style="display: flex; justify-content: center">
                  <router-link
                    to="/jobs/Pakistan/Karachi"
                    class="job_city_title"
                    style="
                      position: absolute;
                      font-size: 24;
                      font-size: 28px;
                      margin-top: 24%;
                      color: white;
                    "
                    >KARACHI</router-link
                  >
                </div>
                <img
                  src="http://historic.job4u.pk/htmlcss/images/sindh.jpg"
                  class="img-fluid"
                  alt=""
                />

                <div class="card-body">
                  <ul
                    class="list-unstyled d-flex justify-content-between mb-0"
                    style="flex-direction: column"
                  >
                    <li
                      class="h6 mb-0 jobs"
                      style="margin-bottom: 3% !important"
                    >
                      <b>Jobs in sindh</b>
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Karachi"
                        class="title h6 text-dark"
                        >Jobs in karachi</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Hyderabad"
                        class="title h6 text-dark"
                        >Jobs in hyderabad</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Sakhar"
                        class="title h6 text-dark"
                        >Jobs in sakhar</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Dadu"
                        class="title h6 text-dark"
                        >Jobs in dadu</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Larkana"
                        class="title h6 text-dark"
                        >Jobs in larkana</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="title h6 text-dark thumbnailch">
              <div class="card catagories overflow-hidden rounded border-0">
                <div style="display: flex; justify-content: center">
                  <router-link
                    to="/jobs/Pakistan/Peshawer"
                    class="job_city_title"
                    style="
                      position: absolute;
                      font-size: 24;
                      font-size: 28px;
                      margin-top: 24%;
                      color: white;
                    "
                    >PESHAWER</router-link
                  >
                </div>
                <img
                  src="http://historic.job4u.pk/htmlcss/images/kpk.jpg"
                  class="img-fluid"
                  alt=""
                />

                <div class="card-body">
                  <ul
                    class="list-unstyled d-flex justify-content-between mb-0"
                    style="flex-direction: column"
                  >
                    <li
                      class="h6 mb-0 jobs"
                      style="margin-bottom: 3% !important"
                    >
                      <b>Jobs in KPK</b>
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Peshawer"
                        class="title h6 text-dark"
                        >Jobs in peshawer</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Abbotabad"
                        class="title h6 text-dark"
                        >Jobs in abbotabad</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Swabi"
                        class="title h6 text-dark"
                        >Jobs in swabi</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Kohat"
                        class="title h6 text-dark"
                        >Jobs in kohat</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/DI Khan"
                        class="title h6 text-dark"
                        >Jobs in DI khan</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="title h6 text-dark thumbnailch">
              <div class="card catagories overflow-hidden rounded border-0">
                <div style="display: flex; justify-content: center">
                  <router-link
                    to="/jobs/Pakistan/Quetta"
                    class="job_city_title"
                    style="
                      position: absolute;
                      font-size: 24;
                      font-size: 28px;
                      margin-top: 24%;
                      color: white;
                    "
                    >QUETTA</router-link
                  >
                </div>
                <img
                  src="http://historic.job4u.pk/htmlcss/images/balochistan.jpg"
                  class="img-fluid"
                  alt=""
                />

                <div class="card-body">
                  <ul
                    class="list-unstyled d-flex justify-content-between mb-0"
                    style="flex-direction: column"
                  >
                    <li
                      class="h6 mb-0 jobs"
                      style="margin-bottom: 3% !important"
                    >
                      <b>Jobs in Balochistan</b>
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Quetta"
                        class="title h6 text-dark"
                        >Jobs in quetta</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Gawader"
                        class="title h6 text-dark"
                        >Jobs in gawader</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Ziarat"
                        class="title h6 text-dark"
                        >Jobs in ziarat</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Chaman"
                        class="title h6 text-dark"
                        >Jobs in chaman</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Sui"
                        class="title h6 text-dark"
                        >Jobs in sui</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="title h6 text-dark thumbnailch">
              <div class="card catagories overflow-hidden rounded border-0">
                <div style="display: flex; justify-content: center">
                  <router-link
                    to="/jobs/Pakistan/Mirpur"
                    class="job_city_title"
                    style="
                      position: absolute;
                      font-size: 24;
                      font-size: 28px;
                      margin-top: 24%;
                      color: white;
                    "
                    >MIRPUR</router-link
                  >
                </div>
                <img
                  src="http://historic.job4u.pk/htmlcss/images/kashmir.jpg"
                  class="img-fluid"
                  alt=""
                />

                <div class="card-body">
                  <ul
                    class="list-unstyled d-flex justify-content-between mb-0"
                    style="flex-direction: column"
                  >
                    <li
                      class="h6 mb-0 jobs"
                      style="margin-bottom: 3% !important"
                    >
                      <b>Jobs in kashmir</b>
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Muzafrabad"
                        class="title h6 text-dark"
                        >Jobs in muzafrabad</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Mirpur"
                        class="title h6 text-dark"
                        >Jobs in mirpur</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Kotli"
                        class="title h6 text-dark"
                        >Jobs in kotli</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Bagh"
                        class="title h6 text-dark"
                        >Jobs in bagh</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Haveli"
                        class="title h6 text-dark"
                        >Jobs in haveli</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="title h6 text-dark thumbnailch browse_local_jobs_ch">
              <div class="card catagories overflow-hidden rounded border-0">
                <div style="display: flex; justify-content: center">
                  <router-link
                    to="/jobs/Pakistan/Gilgit"
                    class="job_city_title"
                    style="
                      position: absolute;
                      font-size: 24;
                      font-size: 28px;
                      margin-top: 24%;
                      color: white;
                    "
                    >GILGIT</router-link
                  >
                </div>
                <img
                  src="http://historic.job4u.pk/htmlcss/images/baltistan.jpg"
                  class="img-fluid"
                  alt=""
                />

                <div class="card-body">
                  <ul
                    class="list-unstyled d-flex justify-content-between mb-0"
                    style="flex-direction: column"
                  >
                    <li
                      class="h6 mb-0 jobs"
                      style="margin-bottom: 3% !important"
                    >
                      <b>Jobs in gilgit baltistan</b>
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Gilgit"
                        class="title h6 text-dark"
                        >Jobs in gilgit</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Skardu"
                        class="title h6 text-dark"
                        >Jobs in skardu</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Hunza"
                        class="title h6 text-dark"
                        >Jobs in hunza</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Nagar"
                        class="title h6 text-dark"
                        >Jobs in nagar</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div> -->
      <!--end col-->
      <!--end row-->
      <!-- </div> -->
      <!--end container-->
      <!-- Candidates Start -->
      <div class="container mt-100 mt-17 mb-17 d-none">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h1 class="heading_featured align-center m_title">
                Featured recruiters
              </h1>
            </div>
          </div>
        </div>

        <div class="row">
          <div
            class="col-lg-3 col-md-6 mt-4 pt-2"
            v-for="(item, index) in featureCompaniesData"
            :key="index"
          >
            <div class="card team text-center border-0">
              <div class="card-body job_tiles_ch">
                <div class="position-relative">
                  <router-link
                    :to="
                      '/employer/' +
                      item.company_name
                        .toLowerCase()
                        .replace(/ /g, '-')
                        .replace(/[^\w-]+/g, '')
                    "
                    class="text-dark company-name"
                  >
                    <img
                      :src="mediaUrl + item.profile_pic"
                      class="img-fluid avatar avatar-ex-large rounded-circle"
                      alt=""
                    />
                  </router-link>
                </div>
                <div class="content pt-3 pb-3">
                  <h5 class="mb-0">
                    <router-link
                      :to="
                        '/employer/' +
                        item.company_name
                          .toLowerCase()
                          .replace(/ /g, '-')
                          .replace(/[^\w-]+/g, '')
                      "
                      class="text-dark company-name"
                      >{{ item && item.company_name }}</router-link
                    >
                  </h5>
                  <small class="designation text-muted">{{
                    item.branches[0].branchAddress
                  }}</small>
                  <ul
                    class="list-unstyled social-icon team-icon mb-0"
                    style="bottom: -43%"
                  >
                    <li
                      class="list-inline-item"
                      v-if="item.facebook && item.facebook.length > 0"
                    >
                      <a target="_blank" :href="item.facebook" class="rounded">
                        <facebook-icon
                          class="fea icon-sm fea-social"
                        ></facebook-icon>
                      </a>
                    </li>
                    <li
                      class="list-inline-item ml-1"
                      v-if="item.instagram && item.instagram.length > 0"
                    >
                      <a target="_blank" :href="item.instagram" class="rounded">
                        <instagram-icon
                          class="fea icon-sm fea-social"
                        ></instagram-icon>
                      </a>
                    </li>
                    <li
                      class="list-inline-item ml-1"
                      v-if="item.twitter && item.twitter.length > 0"
                    >
                      <a target="_blank" :href="item.twitter" class="rounded">
                        <twitter-icon
                          class="fea icon-sm fea-social"
                        ></twitter-icon>
                      </a>
                    </li>
                    <li
                      class="list-inline-item ml-1"
                      v-if="item.linkedin && item.linkedin.length > 0"
                    >
                      <a target="_blank" :href="item.linkedin" class="rounded">
                        <linkedin-icon
                          class="fea icon-sm fea-social"
                        ></linkedin-icon>
                      </a>
                    </li>

                    <li
                      class="list-inline-item ml-1"
                      v-if="item.subscribe == 1"
                    >
                      <a
                        @click.prevent="subscribe(item)"
                        class="rounded"
                        style="cursor: pointer"
                      >
                        <bell-off-icon
                          class="fea icon-sm fea-social"
                        ></bell-off-icon>
                      </a>
                    </li>
                    <li
                      class="list-inline-item ml-1"
                      v-if="item.subscribe == 0"
                    >
                      <a
                        @click.prevent="subscribe(item)"
                        class="rounded"
                        style="cursor: pointer"
                      >
                        <bell-icon class="fea icon-sm fea-social"></bell-icon>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="row explorech hide_on_mobile_ch"
        style="background: url(images/find-more.png) no-repeat center center"
      >
        <div class="container">
          <div class="row">
            <div class="col-sm-6">
              <div class="pull-left">
                <h1>
                  We will help to find
                  <br />
                  the Jobs that are right for you
                </h1>
                <p>
                  A better career is out there.
                  <br />
                  We'll help you find it to become
                  <br />
                  everything you want to be.
                </p>
                <br />
                <a
                  href="/recruiter-registeration"
                  class="btn btn-default btn-lg outline-btn-inverse"
                  >Start Now</a
                >
              </div>
            </div>

            <div class="col-sm-6 pl-20">
              <div class="pull-left">
                <h1>
                  Explore our recruiters <br />
                  diretory
                </h1>
                <p></p>
                <br />
                <a
                  href="/recruiter-directory"
                  class="btn btn-default btn-lg outline-btn-inverse"
                  style="position: absolute; bottom: 0"
                  >Explore Now</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Candidates End -->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>

  <style scoped>
body {
  font-size: 15px !important;
}
.text-muted {
  color: black !important;
}
.settings {
  display: none !important;
}
._bold {
  font-weight: bold;
}
.pix12 {
  font-size: 12px;
}
.vjs_video_3-dimensions {
  width: 602px !important;
  height: 376px !important;
}

.video-js[tabindex="-1"] {
  outline: none;
  /* width: 177%; */
}

@media (min-width: 1024px) {
  .job-profile {
    top: 0px !important;
  }
}

.job_city_title:link {
  text-decoration: none !important;
}
.job_city_title:visited {
  text-decoration: none !important;
}
.job_city_title:hover {
  text-decoration: none !important;
}
.job_city_title:hover {
  color: white !important;
}
.job_city_title:active {
  text-decoration: none !important;
}
.bold {
  font-weight: bold !important;
}
.filter_heading {
  font-weight: bold;
  font-size: 13px;
  padding-left: 5%;
  padding-top: 5%;
  padding-bottom: 5%;
}
.detail_heading {
  font-weight: bold !important;
  font-size: 13px !important;
}
.detail_body {
  font-size: 13px !important;
}
.multiselect__placeholder {
  font-size: 13px !important;
}
.multiselect__option {
  font-size: 13px !important;
}
.multiselect__tag {
  font-size: 10px !important;
}
.job-box:hover {
  -webkit-transform: none !important;
  transform: none !important;
}
fieldset[disabled] .multiselect {
  pointer-events: none;
}
.multiselect__spinner {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 48px;
  height: 35px;
  background: #fff;
  display: block;
}
.multiselect__spinner:after,
.multiselect__spinner:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border-color: #41b883 transparent transparent;
  border-style: solid;
  border-width: 2px;
  box-shadow: 0 0 0 1px transparent;
}
.multiselect__spinner:before {
  animation: a 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
  animation-iteration-count: infinite;
}
.multiselect__spinner:after {
  animation: a 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
  animation-iteration-count: infinite;
}
.multiselect__loading-enter-active,
.multiselect__loading-leave-active {
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}
.multiselect__loading-enter,
.multiselect__loading-leave-active {
  opacity: 0;
}
.multiselect,
.multiselect__input,
.multiselect__single {
  font-family: inherit;
  font-size: 14px;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
.multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: 40px;
  text-align: left;
  color: #35495e;
}
.multiselect * {
  box-sizing: border-box;
}
.multiselect:focus {
  outline: none;
}
.multiselect--disabled {
  pointer-events: none;
  opacity: 0.6;
}
.multiselect--active {
  z-index: 1;
}
.multiselect--active .multiselect__current,
.multiselect--active .multiselect__input,
.multiselect--active .multiselect__tags {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.multiselect--active .multiselect__select {
  transform: rotate(180deg);
}
.multiselect--above.multiselect--active .multiselect__current,
.multiselect--above.multiselect--active .multiselect__input,
.multiselect--above.multiselect--active .multiselect__tags {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.multiselect__input,
.multiselect__single {
  position: relative;
  display: inline-block;
  min-height: 20px;
  line-height: 20px;
  border: none;
  border-radius: 5px;
  background: #fff;
  padding: 1px 0 0 5px;
  width: 100%;
  transition: border 0.1s ease;
  box-sizing: border-box;
  margin-bottom: 8px;
}
.multiselect__tag ~ .multiselect__input,
.multiselect__tag ~ .multiselect__single {
  width: auto;
}
.multiselect__input:hover,
.multiselect__single:hover {
  border-color: #cfcfcf;
}
.multiselect__input:focus,
.multiselect__single:focus {
  border-color: #a8a8a8;
  outline: none;
}
.multiselect__single {
  padding-left: 6px;
  margin-bottom: 8px;
}
.multiselect__tags {
  min-height: 38px !important;
  padding: 10px 40px 0 8px !important;
}
.multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 4px 26px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #41b883;
  margin-bottom: 8px;
  white-space: nowrap;
}
.multiselect__tag-icon {
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-weight: 700;
  font-style: normal;
  width: 22px;
  text-align: center;
  line-height: 22px;
  transition: all 0.2s ease;
  border-radius: 5px;
}
.multiselect__tag-icon:after {
  content: "\D7";
  color: #266d4d;
  font-size: 14px;
}
.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #369a6e;
}
.multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
  color: #fff;
}
.multiselect__current {
  min-height: 40px;
  overflow: hidden;
  padding: 8px 12px 0;
  padding-right: 30px;
  white-space: nowrap;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
}
.multiselect__current,
.multiselect__select {
  line-height: 16px;
  box-sizing: border-box;
  display: block;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
}
.multiselect__select {
  position: absolute;
  width: 40px;
  height: 38px;
  right: 1px;
  top: 1px;
  padding: 4px 8px;
  text-align: center;
  transition: transform 0.2s ease;
}
.multiselect__select:before {
  position: relative;
  right: 0;
  top: 65%;
  color: #999;
  margin-top: 4px;
  border-style: solid;
  border-width: 5px 5px 0;
  border-color: #999 transparent transparent;
  content: "";
}
.multiselect__placeholder {
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
.multiselect--active .multiselect__placeholder {
  display: none;
}
.multiselect__content {
  position: absolute;
  list-style: none;
  display: block;
  background: #fff;
  width: 100%;
  max-height: 240px;
  overflow: auto;
  padding: 0;
  margin: 0;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 1;
}
.multiselect--above .multiselect__content {
  bottom: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  border-top: 1px solid #e8e8e8;
}
.multiselect__content::webkit-scrollbar {
  display: none;
}
.multiselect__element {
  display: block;
}
.multiselect__option {
  display: block;
  padding: 12px;
  min-height: 40px;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}
.multiselect__option:after {
  top: 0;
  right: 0;
  position: absolute;
  line-height: 40px;
  padding-right: 12px;
  padding-left: 20px;
}
.multiselect__option--highlight {
  background: #41b883;
  outline: none;
  color: #fff;
}
.multiselect__option--highlight:after {
  content: attr(data-select);
  background: #41b883;
  color: #fff;
}
.multiselect__option--selected {
  background: #f3f3f3;
  color: #35495e;
  font-weight: 700;
}
.multiselect__option--selected:after {
  content: attr(data-selected);
  color: silver;
}
.multiselect__option--selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #fff;
}
.multiselect__option--selected.multiselect__option--highlight:after {
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff;
}
.multiselect--disabled {
  background: #ededed;
  pointer-events: none;
}
.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select,
.multiselect__option--disabled {
  background: #ededed;
  color: #a6a6a6;
}
.multiselect__option--disabled {
  cursor: text;
  pointer-events: none;
}
.multiselect__option--disabled.multiselect__option--highlight {
  background: #dedede !important;
}
.multiselect-enter-active,
.multiselect-leave-active {
  transition: all 0.15s ease;
}
.multiselect-enter,
.multiselect-leave-active {
  opacity: 0;
}
@keyframes a {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(2turn);
  }
}
</style>
 